<script setup lang="ts">
import { onKeyStroke } from "@vueuse/core";
import { HTMLAttributes, computed } from "vue";
import * as accordion from "@zag-js/accordion";
import { useMachine, normalizeProps } from "@zag-js/vue";
import { SvgIcon } from "../";

const props = withDefaults(
	defineProps<{
		disableClickAway?: boolean;
		disableEsc?: boolean;
	}>(),
	{
		disableClickAway: false,
		disableEsc: false
	}
);

onKeyStroke("Escape", () => close("esc"));

const [state, send] = useMachine(
	accordion.machine({
		id: (Math.random() + 1).toString(36).substring(7),
		collapsible: true
	})
);

const api = computed(() => accordion.connect(state.value as any, send, normalizeProps));

function close(type: "click" | "esc"): void {
	if ((type === "click" && !props.disableClickAway) || (type === "esc" && !props.disableEsc)) {
		api.value.setValue([]);
	}
}

function start(el: Element): void {
	(el as HTMLDivElement).style.height = el.scrollHeight + "px";
}

function end(el: Element): void {
	(el as HTMLDivElement).style.height = "";
}

function getProps(id: string): HTMLAttributes {
	const data = api.value.getItemContentProps({ value: id });
	delete data.hidden;
	return data;
}
</script>

<template>
	<div v-bind="api.rootProps">
		<div class="bg-white border-b border-brown z-20 relative h-[60px]">
			<button
				class="container py-4 flex justify-between gap-4 outline-yellow"
				v-bind="api.getItemTriggerProps({ value: 'dropdown' })"
			>
				<div class="flex gap-1 items-center">
					<span class="text-base leading-7">
						<slot name="header-label" />
					</span>
					<SvgIcon
						name="chevron-down"
						class="duration-200 ease-in-out"
						:class="{ 'rotate-180': api.value.includes('dropdown') }"
					/>
				</div>
				<span class="text-base leading-7 font-bold">
					<slot name="header-price" />
				</span>
			</button>
		</div>
		<Transition
			enter-active-class="duration-300 ease-in-out"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="duration-300 ease-in-out"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-if="api.value.includes('dropdown')"
				class="fixed z-10 inset-0 bg-black/50"
				:class="{ 'cursor-pointer': !disableClickAway }"
				@click="close('click')"
			/>
		</Transition>
		<Transition name="expand" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
			<div
				v-show="getProps('dropdown')['data-state' as keyof HTMLAttributes] === 'open'"
				v-bind="getProps('dropdown')"
				tabindex="-1"
				class="bg-white overflow-hidden outline-yellow z-20 relative"
			>
				<div class="container pt-4 pb-6 flex flex-col gap-4">
					<div class="space-y-6">
						<slot name="products" />
					</div>
					<Divider />
					<div class="space-y-4">
						<slot name="info" />
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<style scoped>
.expand-enter-active,.expand-leave-active{@apply duration-200 ease-in-out overflow-hidden}.expand-enter-from,.expand-leave-to{@apply !h-0 opacity-0}
</style>
